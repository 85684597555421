const cfg = {};

cfg.BASE_URL = process.env.VUE_APP_BASE_API ?? "";
cfg.BASE_IDENTITY_URL = "/identity";
cfg.BASE_IDENTITY_VERSION_URL = "/identity/v0";
cfg.BASE_2FA_URL = "/two-fa";
cfg.BASE_2FA_VERSION_URL = "/two-fa/v0";
cfg.BASE_QRE_MANAGER_URL = "/qre-manager";
cfg.BASE_QRE_MANAGER_VERSION_URL = "/qre-manager/v0";

export default cfg;
