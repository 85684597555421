import Vue from "vue";
import VueRouter from "vue-router";

const Login = () => import("@/views/LoginView.vue");
const UserView = () => import("@/views/UserView.vue");
const AdminView = () => import("@/views/AdminView.vue");
const AccountView = () => import("@/views/AccountView.vue");

//User section
const QREs = () => import("@/views/User/QREs/QREs.vue");
const SingleQRE = () => import("@/views/User/QREs/SingleQRE.vue");
const UserTerminalQre = () => import("@/views/Admin/QREs/QreRescueConsole.vue");
const UserQreLogs = () => import("@/views/Admin/QREs/QreLogs.vue");
const Organizations = () =>
  import("@/views/User/Organizations/Organizations.vue");
const SingleOrganization = () =>
  import("@/views/User/Organizations/SingleOrganization.vue");
const SingleOrganizationNewUser = () =>
  import("@/views/User/Organizations/SingleOrganizationNewUser.vue");

// Admin Section
const Nodes = () => import("@/views/Admin/Nodes/NodesTable.vue");
const SingleNode = () => import("@/views/Admin/Nodes/SingleNode.vue");
const AdminQres = () => import("@/views/Admin/QREs/QreTable.vue");
const CreateQRE = () => import("@/views/Admin/QREs/CreateQRE.vue");
const EditQRE = () => import("@/views/Admin/QREs/EditQRE.vue");
const AdminSingleQRE = () => import("@/views/Admin/QREs/SingleQRE.vue");
const TerminalQre = () => import("@/views/Admin/QREs/QreRescueConsole.vue");
const QreLogs = () => import("@/views/Admin/QREs/QreLogs.vue");
const Users = () => import("@/views/Admin/Users/UsersTable.vue");
const SingleUser = () => import("@/views/Admin/Users/SingleUser.vue");
const CreateUser = () => import("@/views/Admin/Users/CreateUser.vue");
const CreateOrganization = () =>
  import("@/views/Admin/Organizations/CreateOrganization.vue");
const OrganizationsAdmin = () =>
  import("@/views/Admin/Organizations/OrganizationsTable.vue");
const SingleOrganizationAdmin = () =>
  import("@/views/Admin/Organizations/SingleOrganization.vue");

const Account = () => import("@/views/Account/AccountInformation.vue");
const Password = () => import("@/views/Account/ChangePassword.vue");
const Language = () => import("@/views/Account/LanguageSelector.vue");

const ResetPasswordEmail = () => import("@/views/ResetPasswordEmailView");
const ResetPasswordForm = () => import("@/views/ResetPasswordFormView");

const LinkAccounts = () => import("@/views/LinkAccounts.vue");

import E404 from "@/views/NotFoundView.vue";

import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "User",
    component: UserView,
    redirect: "/qres",
    meta: { requiresAuth: true },
    children: [
      { path: "qres", component: QREs },
      { path: "qres/:id", component: SingleQRE },
      { path: "qres/:id/terminal", component: UserTerminalQre },
      { path: "qres/:id/logs", component: UserQreLogs },
      { path: "organizations", component: Organizations },
      { path: "organization/:id", component: SingleOrganization },
      {
        path: "organization/:id/new-user",
        component: SingleOrganizationNewUser,
      },
    ],
  },
  {
    path: "/Admin",
    name: "Admin",
    component: AdminView,
    meta: { requiresAuth: true, requiresAdmin: true },
    children: [
      { path: "users", component: Users },
      { path: "nodes", component: Nodes },
      { path: "nodes/:id", component: SingleNode },
      { path: "qres", component: AdminQres },
      { path: "qres/new", component: CreateQRE },
      { path: "qres/:id/edit", component: EditQRE },
      { path: "qres/:id", component: AdminSingleQRE },
      { path: "qres/:id/terminal", component: TerminalQre },
      { path: "qres/:id/logs", component: QreLogs },
      { path: "users/new", component: CreateUser },
      { path: "users/:username", component: SingleUser },
      { path: "organizations", component: OrganizationsAdmin },
      { path: "organizations/new", component: CreateOrganization },
      { path: "organization/:id", component: SingleOrganizationAdmin },
    ],
  },
  {
    path: "/login/:ref?",
    name: "Login",
    component: Login,
    meta: { guest: true },
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    component: ResetPasswordEmail,
  },
  {
    path: "/new-password/:token?/:new?",
    name: "New Password",
    component: ResetPasswordForm,
  },
  {
    path: "/link-accounts/:user_id?/:tq42_id?/:code?",
    name: "Link Accounts",
    component: LinkAccounts,
  },

  {
    path: "/self",
    name: "Account",
    component: AccountView,
    meta: { requiresAuth: true },
    children: [
      { path: "account", component: Account },
      { path: "password", component: Password },
      { path: "language", component: Language },
    ],
  },
  {
    path: "*",
    name: "CatchAll",
    component: E404,
    meta: { guest: true },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((route) => route.meta.requiresAdmin)) {
    if (store.getters.isAdmin) {
      next();
    } else {
      next("/qres");
    }
  } else if (to.matched.some((route) => route.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
    } else {
      next("/login");
    }
  } else {
    next();
  }
});

export default router;
