<template>
  <div class="full d-flex align-items-center justify-content-center">
    <h1 class="page-title">{{ $t("NotFound") }}</h1>
  </div>
</template>

<script>
export default {
  name: "E404",
  mounted() {
    console.log("Error 404: " + window.location);
  },
};
</script>

<style scoped>
.full {
  height: 100vh;
}
</style>
