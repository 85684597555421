import {
  isLoggedIn,
  setAuthTokens,
  clearAuthTokens,
  getAccessToken,
  getRefreshToken,
} from "axios-jwt";

const state = {
  data: null,
};

const getters = {
  // data
  username: (state) => (state.data != null ? state.data.name : null),
  email: (state) => (state.data != null ? state.data.email : null),
  activeRole: (state) => (state.data !== null ? state.data.activeRole : null),
  roles: (state) => (state.data !== null ? state.data.roles : null),
  rolesDisplay: (state) =>
    state.data !== null ? state.data.rolesDisplay : null,
  // booleans
  isAuthenticated: (state) => state.data != null && isLoggedIn(),
  isAdmin: (state) =>
    state.data != null && state.data.activeRole === "system.admin",
  /// jwt
  jwt: (state) => state.data,
  jwtToken: (state) => (state.data != null ? getAccessToken() : null),
  jwtRefreshTtoken: (state) => (state.data != null ? getRefreshToken() : null),
};

const actions = {
  async login({ commit }, data) {
    setAuthTokens({
      accessToken: data.token,
      refreshToken: data.refreshToken,
    });
    this._vm.$cookies.set("AUTH_TOKEN", data.token);
    this._vm.$cookies.set("AUTH_TOKEN_REFRESH", data.refreshToken);
    commit("login", data);
  },
  async logout({ commit }) {
    clearAuthTokens();
    this._vm.$cookies.remove("AUTH_TOKEN");
    this._vm.$cookies.remove("AUTH_TOKEN_REFRESH");
    commit("logout");
  },
  async changeRole({ commit }, data) {
    setAuthTokens({
      accessToken: data.token,
      refreshToken: data.refreshToken,
    });
    this._vm.$cookies.set("AUTH_TOKEN", data.token);
    this._vm.$cookies.set("AUTH_TOKEN_REFRESH", data.refreshToken);
    commit("refresh", data);
  },
};

const mutations = {
  login(state, token) {
    state.data = token;
  },
  refresh(state, token) {
    state.data = token;
  },
  logout(state) {
    state.data = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
