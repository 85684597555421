import cfg from "../config";
import axios from "axios";
import store from "../store";
import router from "../router";
import { app } from "@/main";
import { applyAuthTokenInterceptor, getAccessToken } from "axios-jwt";

const axiosInstance = axios.create({
  baseURL: cfg.BASE_URL,
});

const BASE_IDENTITY_VERSION_URL = cfg.BASE_URL + cfg.BASE_IDENTITY_VERSION_URL;

const requestRefresh = async (refresh) => {
  try {
    const {
      data: { token },
    } = await axios.put(
      `${BASE_IDENTITY_VERSION_URL}/refresh-login`,
      { refreshToken: refresh },
      { headers: { Authorization: "Bearer " + getAccessToken() } }
    );

    return token;
  } catch (error) {
    app.$bvToast.toast("Your session has expired. Please login again.", {
      title: `Session expired`,
      toaster: "b-toaster-bottom-right",
      variant: "danger",
    });

    await store.dispatch("logout");
    await router.replace({
      name: "Login",
      query: { ref: encodeURIComponent(app.$route.path) },
    });
    return;
  }
};

applyAuthTokenInterceptor(axiosInstance, { requestRefresh });

// global error toast
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    let preventDefault = false;

    if (
      error.response &&
      error.response.config &&
      error.response.config.errors &&
      error.response.config.errors[error.response.status]
    ) {
      preventDefault =
        error.response.config.errors[error.response.status](error);
    }

    // this condition is true as long as the session is active and not 500
    if (error.response && error.response.status !== 500 && !preventDefault) {
      app.$bvToast.toast(error.response.data.message, {
        title: `Something went wrong`,
        toaster: "b-toaster-bottom-right",
        variant: "danger",
      });
    } else if (error.response.status && !preventDefault) {
      app.$bvToast.toast("Server Error!", {
        title: `Something went wrong`,
        toaster: "b-toaster-bottom-right",
        variant: "danger",
      });
    }

    throw error;
  }
);

export default axiosInstance;
