import Vue from "vue";
import App from "./App.vue";

import axios from "./axios";
import cfg from "./config";
import router from "./router";
import store from "./store";

import VueTypeaheadBootstrap from "vue-typeahead-bootstrap";

import VueCookies from "vue-cookies";

import "./assets/styles/main.scss";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import i18n from "./i18n";

Vue.config.productionTip = true;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueCookies);
Vue.component("vue-typeahead-bootstrap", VueTypeaheadBootstrap);

Vue.prototype.$http = axios;

Vue.prototype.$cfg = cfg;

export const app = new Vue({
  store,
  router,
  i18n,
  render: (r) => r(App),
}).$mount("#app");
